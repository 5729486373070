var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kiosk-wrapper"
  }, [_c('div', {
    staticClass: "row kiosk-header"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_c('h1', {
    staticClass: "text-primary mb-1 fade",
    class: {
      in: !_vm.hide
    }
  }, [_vm._v(_vm._s(_vm.title))]), _c('h2', {
    staticClass: "text-muted mb-0 fade",
    class: {
      in: !_vm.hide
    }
  }, [_vm._v(_vm._s(_vm.subTitle))])]), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "max-height": "100px"
    },
    attrs: {
      "src": _vm.kiosk.entity.companyLogo
    }
  }), _c('div', {
    staticClass: "text-left ml-5 w-50"
  }, [_c('p', {
    staticClass: "font-w500 text-muted mb-0"
  }, [_vm._v("Powered by")]), _c('Logo')], 1)])])]), !_vm.loading ? _c('div', {
    staticClass: "fade kiosk-body",
    class: {
      in: !_vm.hide
    }
  }, [_vm._t("default")], 2) : _c('div', {
    staticClass: "pt-5"
  }, [_c('Spinner')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }