<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    weekdays: {
      type: Boolean,
      default: false
    },
    compare: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      // Must be daily granularity data
      const weekArray = Array.from({ length: moment(this.period.endDate).diff(this.period.startDate, 'days') + 1 }, (_, i) =>
        moment(this.period.startDate).add(i, 'days')
      );

      console.log('weekArray', weekArray);

      const series = [];

      const weekSeries = weekArray.map(date => {
        const dayData = this.data.filter(item => moment(item.startDate).isSame(date, 'day'));

        const item = {
          value: dayData.reduce((acc, item) => acc + item.consumption, 0)
        };

        // if (i === weekArray.length - 1) {
        //   item.itemStyle = {
        //     color: '#6dc6cd'
        //   };
        // }

        return item;
      });

      series.push({
        name: 'This week',
        type: 'bar',
        data: weekSeries,
        itemStyle: {
          borderRadius: [5, 5, 0, 0]
        }
      });

      if (this.compare) {
        const weekBeforeSeries = weekArray.map(date => {
          const weekBeforeDate = moment(date).subtract(1, 'week');
          const dayData = this.data.filter(item => moment(item.startDate).isSame(weekBeforeDate, 'day'));

          const item = {
            value: dayData.reduce((acc, item) => acc + item.consumption, 0)
          };

          return item;
        });

        series.unshift({
          name: 'Last week',
          type: 'bar',
          data: weekBeforeSeries,
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          }
        });
      }

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Day',
          type: 'category',
          data: weekArray.map(date => date.format(this.weekdays ? 'ddd Do' : 'Do'))
        },
        yAxis: {
          type: 'value',
          name: this.units,
          splitNumber: 2
        },
        series,
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
