var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KioskSlide', {
    attrs: {
      "title": "Building Energy Usage",
      "sub-title": "Energy usage at ".concat(_vm.kiosk.asset.siteName),
      "hide": _vm.hideSlide
    }
  }, [_c('div', {
    staticClass: "p-5 border-bottom"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Yesterday's Usage")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.yesterdayUsage)) + " kWh")]), _c('div', {
    staticClass: "mb-4 font-size-lg"
  }, [_vm.yesterdayVsLastWeek < 0 ? _c('span', {
    staticClass: "bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.yesterdayVsLastWeek)) + "%")]) : _vm.yesterdayVsLastWeek > 0 ? _c('span', {
    staticClass: "bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.yesterdayVsLastWeek)) + "%")]) : _c('span', {
    staticClass: "bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.yesterdayVsLastWeek)) + "%")]), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v("Compared to same day last week")])]), _c('div', {
    staticClass: "font-size-h4 font-w500 text-muted"
  }, [_vm._v("Usage this week has been slightly higher so far compared to last week.")])]), _c('div', {
    staticClass: "col"
  }, [_c('AssetWeeklyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.weeklyGraph.period,
      "data": _vm.consumption.current.data,
      "units": "kWh",
      "weekdays": "",
      "compare": ""
    }
  })], 1)])], 1), _c('div', {
    staticClass: "p-5"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Week Usage")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.weekUsage)) + " kWh")]), _c('div', {
    staticClass: "mb-4 font-size-lg"
  }, [_vm.weekVsLastWeek < 0 ? _c('span', {
    staticClass: "bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.weekVsLastWeek)) + "%")]) : _vm.weekVsLastWeek > 0 ? _c('span', {
    staticClass: "bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.weekVsLastWeek)) + "%")]) : _c('span', {
    staticClass: "bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.weekVsLastWeek)) + "%")]), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v("Compared to the previous 7 days")])]), _c('div', {
    staticClass: "font-size-h4 font-w500 text-muted"
  }, [_vm._v("Usage this week has been slightly higher so far compared to last week.")])]), _c('div', {
    staticClass: "col"
  }, [_c('AssetMonthlyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.monthlyGraph.period,
      "data": _vm.consumption.current.data,
      "units": "kWh"
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }