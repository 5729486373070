<template>
  <KioskSlide title="Building Energy Usage" :sub-title="`Energy usage at ${kiosk.asset.siteName}`" :hide="hideSlide">
    <div class="p-5 border-bottom">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Yesterday's Usage</div>
          <div class="font-w700 display-3 mb-4">{{ yesterdayUsage | numberFormat }} kWh</div>
          <div class="mb-4 font-size-lg">
            <span v-if="yesterdayVsLastWeek < 0" class="bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-arrow-down"></i> {{ Math.abs(yesterdayVsLastWeek) }}%</span
            >
            <span v-else-if="yesterdayVsLastWeek > 0" class="bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-arrow-up"></i> {{ Math.abs(yesterdayVsLastWeek) }}%</span
            >
            <span v-else class="bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-minus"></i> {{ Math.abs(yesterdayVsLastWeek) }}%</span
            >
            <span class="text-muted">Compared to same day last week</span>
          </div>
          <div class="font-size-h4 font-w500 text-muted">Usage this week has been slightly higher so far compared to last week.</div>
        </div>
        <div class="col">
          <AssetWeeklyConsumption style="height: 325px" :period="weeklyGraph.period" :data="consumption.current.data" units="kWh" weekdays compare />
        </div>
      </div>
    </div>
    <div class="p-5">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Week Usage</div>
          <div class="font-w700 display-3 mb-4">{{ weekUsage | numberFormat }} kWh</div>
          <div class="mb-4 font-size-lg">
            <span v-if="weekVsLastWeek < 0" class="bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-arrow-down"></i> {{ Math.abs(weekVsLastWeek) }}%</span
            >
            <span v-else-if="weekVsLastWeek > 0" class="bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-arrow-up"></i> {{ Math.abs(weekVsLastWeek) }}%</span
            >
            <span v-else class="bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"
              ><i class="fa fa-minus"></i> {{ Math.abs(weekVsLastWeek) }}%</span
            >
            <span class="text-muted">Compared to the previous 7 days</span>
          </div>
          <div class="font-size-h4 font-w500 text-muted">Usage this week has been slightly higher so far compared to last week.</div>
        </div>
        <div class="col">
          <AssetMonthlyConsumption style="height: 325px" :period="monthlyGraph.period" :data="consumption.current.data" units="kWh" />
        </div>
      </div>
    </div>
  </KioskSlide>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinnerLogo';
import KioskSlide from './KioskSlide';
import AssetWeeklyConsumption from '@/views/Machine/templates/charts/AssetWeeklyConsumption';
import AssetMonthlyConsumption from '@/views/Machine/templates/charts/AssetMonthlyConsumption';

export default {
  name: 'KioskEnergy',
  components: {
    AssetWeeklyConsumption,
    AssetMonthlyConsumption,
    KioskSlide,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yesterday: moment().subtract(1, 'days').startOf('day')
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      asset: 'kioskLive/asset',
      consumption: 'kioskLive/consumption',
      loadingAction: 'kioskLive/loadingAction',
      loading: 'kioskLive/loading'
    }),
    yesterdayUsage() {
      const startDate = this.yesterday;

      const consumptionItems = this.consumption.current.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    prevYesterdayUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.current.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    weekUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');
      const endDate = this.yesterday;

      const consumptionItems = this.consumption.current.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    prevWeekUsage() {
      const startDate = this.yesterday.clone().subtract(2, 'weeks');
      const endDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.current.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    yesterdayVsLastWeek() {
      return this.percentageDiff(this.yesterdayUsage, this.prevYesterdayUsage);
    },
    weekVsLastWeek() {
      return this.percentageDiff(this.weekUsage, this.prevWeekUsage);
    },
    weeklyGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'week').startOf('day'),
          endDate: this.yesterday
        }
      };
    },
    monthlyGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'month').startOf('day'),
          endDate: this.yesterday
        }
      };
    },
    hhGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'week').startOf('day'),
          endDate: this.yesterday
        }
      };
    }
  },
  methods: {
    percentageDiff(usage, prevUsage) {
      if (prevUsage === 0 && usage !== 0) return 100;
      if (prevUsage === 0 && usage === 0) return 0;
      const pctDiff = (usage - prevUsage) / prevUsage;

      return Math.round(pctDiff * 100 * 10) / 10;
    }
  }
};
</script>
<style lang="scss"></style>
