export default {
  color: [
    '#65C198',
    '#1F303D',
    '#F25F5C',
    '#FFE066',
    '#1B98E0',
    '#91cc75',
    '#c91466',
    '#fac858',
    '#73c0de',
    '#3ba272',
    '#fc8452',
    '#9a60b4',
    '#ea7ccc',
    '#f6c85f',
    '#3e97d1',
    '#6f9eaf',
    '#f6b26b',
    '#b6a2de',
    '#affffa',
    '#aaaaaa',
    '#444444',
    '#ffff00',
    '#f4b7da',
    '#4da9c9',
    '#fda222',
    '#4da555'
  ],
  textStyle: {
    color: '#6c7985',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 21
  },
  xAxis: {
    silent: true,
    axisLine: {
      show: true,
      lineStyle: {
        color: '#6c7985',
        width: 1.5,
        cap: 'butt'
      }
    },
    axisTick: {
      lineStyle: {
        opacity: 0,
        cap: 'square',
        color: '#6c7985'
      }
    },
    z: 5
  },
  yAxis: {
    silent: true,
    splitLine: {
      lineStyle: {
        color: '#6c7985',
        opacity: 0.2
      }
    }
  },
  legend: {
    textStyle: {
      color: '#6c7985'
    },
    itemGap: 20
  },
  animation: false
};
