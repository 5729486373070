<template>
  <div class="kiosk-wrapper">
    <div class="row kiosk-header">
      <div class="col-md-9">
        <h1 class="text-primary mb-1 fade" :class="{ in: !hide }">{{ title }}</h1>
        <h2 class="text-muted mb-0 fade" :class="{ in: !hide }">{{ subTitle }}</h2>
      </div>
      <div class="col-md-3 text-right">
        <div class="d-flex align-items-center justify-content-end">
          <img class="img-fluid" style="max-height: 100px" :src="kiosk.entity.companyLogo" />
          <div class="text-left ml-5 w-50">
            <p class="font-w500 text-muted mb-0">Powered by</p>
            <Logo />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="fade kiosk-body" :class="{ in: !hide }">
      <slot></slot>
    </div>
    <div v-else class="pt-5">
      <Spinner />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo';
import Spinner from '@/components/SpinnerLogo';

export default {
  components: {
    Logo,
    Spinner
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    hide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      loading: 'kioskLive/loading'
    })
  }
};
</script>
